// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#grow-container:has(#grow-summary),
#grow-container:has(main[data-testid="home-main-page"]),
#grow-container:has(div[data-testid="leverage-page"]),
#grow-container:has(div[data-testid="checkout-page-container"]),
#grow-container:has(div[data-testid="product-selection"]),
#grow-container:has(div[data-testid="Dee-container"])
#grow-container:has(div[data-testid="club-b"]) {
  flex: 1;  
  background: transparent;
  padding-top: 1.5rem;
  padding-left: 6.5rem;
  padding-right: 1.5rem;
  min-width: calc(1024px - 1.5rem);
  max-width: 1920px;
  margin: 0 auto;
  padding-bottom: 6rem;
}
`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;;;;;;;EAOE,OAAO;EACP,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;EACpB,qBAAqB;EACrB,gCAAgC;EAChC,iBAAiB;EACjB,cAAc;EACd,oBAAoB;AACtB","sourcesContent":["#grow-container:has(#grow-summary),\n#grow-container:has(main[data-testid=\"home-main-page\"]),\n#grow-container:has(div[data-testid=\"leverage-page\"]),\n#grow-container:has(div[data-testid=\"checkout-page-container\"]),\n#grow-container:has(div[data-testid=\"product-selection\"]),\n#grow-container:has(div[data-testid=\"Dee-container\"])\n#grow-container:has(div[data-testid=\"club-b\"]) {\n  flex: 1;  \n  background: transparent;\n  padding-top: 1.5rem;\n  padding-left: 6.5rem;\n  padding-right: 1.5rem;\n  min-width: calc(1024px - 1.5rem);\n  max-width: 1920px;\n  margin: 0 auto;\n  padding-bottom: 6rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
